import * as React from 'react';
import { View } from 'react-native';
const WebView = View as any;

//  REFERENCE:  https://github.com/necolas/react-native-web/issues/205#issuecomment-311947750
//  THOUGHTS:  Adapted from the above link, BUT this is SUCH a specific slice of functionality that it bothers me... I think there are LOTS of things we could want to listen to on ANY component... slides, press, hover, etc, and we MAY want it to mess with the props or internal state, whatever.  THIS is where I think having something GENERIC to wrap ANY component could be useful.  THEN, let us mess with the props directly.  That way, we can change the style and perhaps mess with children too? hmmm ... things like changing the text color of a nested component... how?  Well, inn CSS, we can apply classes that DO affect sub-components, and WHY should that not also be possible here?  BUT, not just STYLES, ANYTHING? HM!  MAYBE this is part of CONTEXT.  This way, we can indicate that SUB components meeting a condition should have a given transformation performed on their props!  THEN we can build simplifying EXPRESSIONS that can be used to make this easier without writing the same functions several times.  ALL while keeping thigns in "components", or user-level abstractions? Hmm... or maybe we SHOULD rely on the component state... hmmmmmm... It's just a symbol with associated state.  Hmmm...  but it's SELF-CONTAINED functionlaity.  BUT maybe we want some of BOTH.  Self-contained, AND global changes? HM!!
class HoverableView extends React.Component<any, any> {
  // declare public root: any;
  public root: any;
  setStyles = (styles: any) => {
    this.root.setNativeProps({
      style: styles,
    })
  }

  render() {
    const { onHover, style, ...passThrough } = this.props
    return (
      <WebView
        ref={(component: any) => { this.root = component }}
        onMouseEnter={() => { document.body.style.cursor = "pointer"; this.setStyles({ ...style, ...onHover }) }}
        onMouseLeave={() => { document.body.style.cursor = "default"; this.setStyles(style) }}
        style={style}
        {...passThrough}
      />
    )
  }
}

export default HoverableView