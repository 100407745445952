import fetch from 'cross-fetch';
import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from 'expo-image-picker';
import * as React from 'react';
import { Dimensions, Image, ImageBackground, LayoutAnimation, StyleSheet, Text, View, ViewStyle } from 'react-native';
import Markdown from 'react-native-markdown-renderer';
import { useMediaQuery } from 'react-responsive';
import HoverableView from './hoverable/hoverable';
var zipcodes = require('zipcodes');

const NewImage = Image as any;

const WebText = Text as any;

//  REFERENCE:  https://ui.dev/validate-email-address-javascript/
function validateEmailFormat (email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

const Desktop = ({ children }: { children: any }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}
const Tablet = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ minWidth: 500, maxWidth: 767 })
  return isMobile ? children : null
}
const Mobile = ({ children }: { children: any }) => {
  const isMobile = useMediaQuery({ maxWidth: 499 })
  return isMobile ? children : null
}
const Default = ({ children }: { children: any }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

//  REFERENCE:  https://baconbrix.gitbook.io/react-native-web/styling/aspect-ratio
function AspectView(props: any) {
  const [layout, setLayout] = React.useState(null) as any;

  const { aspectRatio = 1, ...inputStyle } =
    StyleSheet.flatten(props.style) || {};
  const style = [inputStyle, { aspectRatio }];

  if (layout) {
    const { width = 0, height = 0 } = layout;
    if (width === 0) {
      style.push({ width: height * aspectRatio, height });
    } else {
      style.push({ width, height: width * aspectRatio });
    }
  }

  return (
    <View
      {...props}
      style={style}
      onLayout={({ nativeEvent: { layout } }) => { if (props.style.aspectRatio !== 'auto') { setLayout(layout) }}}
    />
  );
}

const mdStyles = StyleSheet.create({
  heading: {
    fontSize: 34,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "700"
  },
  heading1: {
    fontSize: 32,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "700"
  },
  heading2: {
    fontSize: 24,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "700"
  },
  heading3: {
    fontSize: 18,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "600"
  },
  heading4: {
    fontSize: 16,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "600"
  },
  heading5: {
    fontSize: 13,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "600"
  },
  heading6: {
    fontSize: 11,
    color: 'black',
    fontFamily: 'soleil',
    fontWeight: "600"
  }
});

const TOS = ({  }: {  }) => {

  const copy = `
  Terms of Service
  ================
  
  Last revised on Oct. 26, 2020
  
  ### The Gist
  
  PopSale operates the Service, which we hope you use. If you use it, please use it responsibly.
  
  That's the basic idea, but You must read through the entire Terms of Service below and agree with all the details before You use the Service.
  
  ### Reuse
  
  This document is an adaptation of the [Heroku Terms of Service](http://legal.heroku.com/tos), which is turn an adaptation of the [Google App Engine Terms of Service](http://code.google.com/appengine/terms.html). The original work has been modified with permission under the [Creative Commons Attribution 3.0 License](http://creativecommons.org/licenses/by/3.0/). Neither Heroku, Inc. nor Google, Inc. is not connected with and does not sponsor or endorse PopSale or its use of the work.
  
  You're welcome to adapt and use this document for your own needs. If you make an improvement, we'd appreciate it if you would let us know so we can consider improving our own document.
  
  ### Your Agreement with PopSale
  
  Your use of the PopSale service is governed by this agreement (the "Terms"). "PopSale" is a Sole Proprietorship based in westerrn Massachusetts. The "Service" includes all services PopSale makes available including but not limited to the web sites [popsale.co], [www.popsale.co], our blog, our API, and any other software, sites, and / or services offered by PopSale. "Content" means all content generated by PopSale on your behalf (including metric data).
  
  In order to use the Service, You (the "Customer", "You", or "Your") must first agree to the Terms. You understand and agree that PopSale will treat Your use of the Service as acceptance of the Terms from that point onwards.
  
  PopSale may make changes to the Terms from time to time. You may reject the changes by terminating Your account. You understand and agree that if You use the Service after the date on which the Terms have changed, PopSale will treat Your use as acceptance of the updated Terms.
  
  If you have any question about the Terms, please contact us at support@popsale.co
  
  ### Your Account
  
  * You may not use the Service if You are a person barred from receiving the Service under the laws of the United States or other countries, including the country in which You are resident or from which You use the Service.
  * You may not use the service unless you are over the age of 18.
  * You must be a human. Account created by automated methods are not permitted.
  
  ### Use of the Service
  
  * Your use of the Service must comply with all applicable laws, regulations and ordinances.
  * You agree to not engage in any activity that interferes with or disrupts the Service.
  * PopSale reserves the right to enforce quotas and usage limits (to any resources, including the API) at its sole discretion, with or without notice, which may result in PopSale disabling or throttling your usage of the Service for any amount of time.
  
  ### Service Policies and Privacy
  
  The Service shall be subject to the privacy policy for the Service. You agree to the use of Your data in accordance with PopSale's privacy policies.
  
  ### Service Disclaimer
  
  * You acknowledge that offers made to You via the Services may be below market value, and PopSale may make a profit from items sold to us.
  * You agree that it is Your responsibility to determine whether or not any offer made to You is satisfactory.
  * You acknowledge that you are under no obligation to accept an offer made to You via the Service.
  
  * The Service may be provided to You without charge up with certain limits or for a certain "trial" period of time.
  * Usage over this limit (or after the "trial" period) or requires Your purchase of additional resources or services.
  * For all purchased resources and services, we will charge Your credit card on a monthly basis.
  * PopSale may change its fees and payment policies for the Service by updating this "Terms of Service" document.
  
  ### Cancellation and Termination

  * You agree that PopSale, in its sole discretion and for any or no reason, may terminate or suspend Your use of the Service. You agree that any termination of Your access to the Service may be without prior notice, and You agree that PopSale will not be liable to You or any third party for such termination.
  
  ### Customer Content
  
  * PopSale claims no ownership or control over any Customer Content (except Feedback). You retain copyright and any other rights You already hold in the Customer Content and You are responsible for protecting those rights, as appropriate.
  * You agree to assume full responsibility for configuring the Service to allow appropriate access to any Customer Content provided to the Service as applicable.
  
  ### Feedback
  
  You may choose to or we may invite You to submit comments or ideas about the Service, including but not limited to ideas about improving the Service or our products ("Ideas"). By submitting any Idea, You agree that Your disclosure is unsolicited and without restriction and will not place PopSale under any fiduciary or other obligation, and that we are free to use the Idea without any additional compensation to You, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone.  Further, you give PopSale a worldwide, royalty-free, non-revocable, assignable, and non-exclusive license to any and all assignable rights in the aforementioned feedback.
  
  ### Modification of the Service
  
  * You acknowledge and agree that the Service may change from time to time without prior notice to You.
  * Changes include, without limitation, changes to fee and payment policies, security patches, added or removed functionality, and other enhancements or restrictions.
  * PopSale shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.
  
  ### External Resources
  
  The Service may include hyperlinks to other web sites or content or resources or email content. You acknowledge and agree that PopSale is not responsible for the availability of any such external sites or resources, and does not endorse any advertising, products or other materials on or available from such web sites or resources.
  
  ### License from PopSale and Restrictions
  
  PopSale gives You a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to You by PopSale as part of the Service as provided to You by PopSale. This license is for the sole purpose of enabling You to use and enjoy the benefit of the Service as provided by PopSale, in the manner permitted by the Terms.
  
  You may not (and You may not permit anyone else to): (a) copy, modify, create a derivative work of, reverse engineer, decompile or otherwise attempt to extract the source code of the Service or any part thereof, unless this is expressly permitted or required by law, or unless You have been specifically told that You may do so by PopSale, in writing (e.g., through an open source software license); or (b) attempt to disable or circumvent any security mechanisms used by the Service.
  
  Open source software licenses for components of the Service released under an open source license constitute separate written agreements. To the limited extent that the open source software licenses expressly supersede these Terms, the open source licenses govern Your agreement with PopSale for the use of the components of the Service released under an open source license.
  
  ### Exclusion of warranties
  
  * You expressly understand and agree that your use of the service is at your sole risk and that the service is provided "as is" and "as available.".
  * You agree that PopSale has no responsibility or liability for the deletion or failure to store any Content and other communications maintained or transmitted through use of the Service. You further acknowledge that You are solely responsible for securing and backing up Customer Content.
  * PopSale does not warrant to you that: (a) your use of the service will meet your requirements, (b) your use of the service will be uninterrupted, timely, secure or free from error, (c) the results or data provided by the Service will be accurate, (d) the quality of the service will meet your expectations and (e) any errors in the Service will be fixed.
  
  ### Limitation of Liability
  
  You expressly understand and agree that PopSale, its subsidiaries and affiliates, and its licensors shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of liability. This shall include, but not be limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation, any loss of data suffered, cost of procurement of substitute goods or services, or other intangible loss (whether or not PopSale has been advised of or should have been aware of the possibility of any such losses arising).
  
  ### Indemnification
  
  You agree to hold harmless and indemnify PopSale, and its subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners (collectively "PopSale and Partners") from and against any third party claim arising from or in any way related to (a) Your breach of the Terms, (b) Your use of the Service, (c) Your violation of applicable laws, rules or regulations in connection with the Service, or (d) Your Customer Content, including any liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs and attorneys' fees, of every kind and nature. In such a case, PopSale will provide You with written notice of such claim, suit or action.
  
  ### General Legal Terms
  
  The Terms constitute the whole legal agreement between You and PopSale and govern Your use of the Service and completely replace any prior agreements between You and PopSale in relation to the Service.
  
  You agree that if PopSale does not exercise or enforce any legal right or remedy which is contained in the Terms (or which PopSale has the benefit of under any applicable law), this will not be taken to be a formal waiver of PopSale's rights and that those rights or remedies will still be available to PopSale.
  
  PopSale shall not be liable for failing or delaying performance of its obligations resulting from any condition beyond its reasonable control, including but not limited to, governmental action, acts of terrorism, earthquake, fire, flood or other acts of God, labor conditions, power failures, and Internet disturbances.
`;

  return (
    <View>
      <Markdown style={ mdStyles }>{copy}</Markdown>
    </View>
  );
}

const PP = ({  }: { }) => {

  const copy = `
  Privacy Policy
  ==============
  
  Last revised on Oct. 26, 2020
  
  ### The Gist
  
  PopSale may collect certain non-personally identifying information about you as you use our sites. We may use this data to better understand our users. We can also publish this data, but the data will be about a large group of users, not individuals.
  
  We will also ask you to provide personal information and use cookies.
  
  That's the basic idea, but you must read through the entire Privacy Policy below and agree with all the details before you use the Service.
  
  ### Reuse
  
  This document is based upon the [Automattic Privacy Policy](http://automattic.com/privacy/) and is licensed under [Creative Commons Attribution Share-Alike License 2.5](http://creativecommons.org/licenses/by-sa/2.5/). Basically, this means you can use it verbatim or edited, but you must release new versions under the same license and you have to credit Automattic somewhere (like this!). Automattic is not connected with and does not sponsor or endorse PopSale or its use of the work.
  
  "PopSale" is a Sole Proprietorship based in westerrn Massachusetts. The "Service" includes all services PopSale makes available including but not limited to the web sites [popsale.co], [www.popsale.co], our blog, our API, and any other software, sites, and / or services offered by PopSale. "Content" means all content generated by PopSale on your behalf (including metric data).  It is PopSale's policy to respect your privacy regarding any information we may collect while operating the Service.
  
  ### Questions
  
  If you have question about this Privacy Policy, please contact us at support@popsale.co
  
  ### Visitors
  
  Like most website operators, PopSale collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. PopSale's purpose in collecting non-personally identifying information is to better understand how PopSale's visitors use its website. From time to time, PopSale may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of the Service.
  
  PopSale also collects potentially personally-identifying information like Internet Protocol (IP) addresses. PopSale does not disclose such information, other than under the same circumstances that it uses and discloses personally-identifying information, as described below. We may also collect and use IP addresses to block users who violated our Terms of Service.
  
  ### Gathering of Personally-Identifying Information
  
  Certain visitors to PopSale's services choose to interact with PopSale in ways that require PopSale to gather personally-identifying information. The amount and type of information that PopSale gathers depends on the nature of the interaction. PopSale does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain Service-related activities.
  
  Additionally, some interactions may ask for optional personal information. For instance, when posting a comment, may provide a website that will be displayed along with a user's name when the comment is displayed. Supplying such personal information is completely optional and is only displayed for the benefit and the convenience of the user.
  
  ### Aggregated Statistics
  
  PopSale may collect statistics about the behavior of visitors to the Service. PopSale may display this information publicly or provide it to others. However, PopSale does not disclose personally-identifying information other than as described below.
  
  ### Protection of Certain Personally-Identifying Information
  
  PopSale discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using the Service, you consent to the transfer of such information to them. PopSale may disclose potentially personally-identifying and personally-identifying information publically when You choose to use a feature in the Service that publicizes this informtion (for example, a personal profile). PopSale discloses potentially personally-identifying and personally-identifying information when required to do so by law, or when PopSale believes in good faith that disclosure is reasonably necessary to protect the property or rights of PopSale, third parties or the public at large. If you are a user of the Service and have supplied your email address, PopSale may send you email. PopSale takes measures to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.
  
  ### Cookies
  A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the Service each time the visitor returns. PopSale may use cookies to help PopSale identify and track visitors, their usage of the Service, and their Service access preferences. PopSale visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using PopSale's websites, with the drawback that certain features of PopSale's websites may not function properly without the aid of cookies.
  
  ### Data Storage
  PopSale uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service. You understand that Your data may be stored on third party storage and transmitted through third party networks.
  
  ### Privacy Policy Changes
  PopSale may change its Privacy Policy from time to time, and in PopSale's sole discretion. PopSale encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change. 
`;

  return (
    <View>
      <Markdown style={ mdStyles }>{copy}</Markdown>
    </View>
  );
}

const shadowStyle = { shadowColor: "#000", shadowOffset: { width: 0, height: 12 }, shadowOpacity: 0.15, shadowRadius: 30.00 };

const Overlay2 = ({ style }: { style: ViewStyle }) => {
  return <View style={{ position: 'absolute', width: '150%', height: '150%', backgroundColor: 'black', opacity: 0.3, ...style }} />;
}

const CenteredContent = ({ children, style }: { children: any, style?: ViewStyle }) => {
  return (
    // Outer Container:  We need to use a row so we can BOTH center AND stretch with a MAX width.
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <View style={{ paddingLeft: 80, paddingRight: 80, maxWidth: 1760, height: 70, display: 'flex', flexDirection: 'row', flex: 1, ...style }}>
        { children }
      </View>
    </View>
  );
}
interface AppProps {
  sizes: any;
}

interface AppState {

  scrollPos: number,

  //  Form Fields
  title?: string;
  description?: string;
  screenName?: string;
  email?: string;
  zipCode?: string;
  isBundle: boolean;
  imageOne?: any;
  imageTwo?: any;
  imageThree?: any;
  imageFour?: any;

  //  UI State
  sharePosition?: number;
  aboutPosition?: number;

  //  Submisson
  pendingSubmission?: boolean;
  submissionModal?: boolean;
  submissionError?: boolean;

  //  Navigation
  tos?: boolean;
  pp?: boolean;
}

export class AppComponentBase extends React.Component<AppProps, AppState> {

  constructor(props: any) {
    super(props);
    this.state = {
      isBundle: false,
      scrollPos: 0
    };
  }

  public componentWillReceiveProps = async () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
  }

  public pickImage = async (id: string) => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.cancelled) {

      const manipResult = await ImageManipulator.manipulateAsync(result.uri,
        [{ resize: { width: 400 } }],
        { compress: 0.6, format: ImageManipulator.SaveFormat.PNG }
      );

      const changeRecord = { [`image${ id }`]: manipResult.uri }
      this.setState(changeRecord as any);
    }
  };

  //  TODO:  Add DETAILS as to WHY a validation is failing, especially for ZipCode.

  private validateEmail = () => {
    return this.state.email ? validateEmailFormat(this.state.email) : false;
  }

  public apiCall = async () => {

    //  Set the Submission
    this.setState({ pendingSubmission: true, submissionModal: true, submissionError: false });

    const res = await fetch(`https://tsogih6iw5.execute-api.us-east-2.amazonaws.com/default/PopSaleEmail`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state)
    });

    if ((res.status !== 201) && (res.status !== 200)) {
      this.setState({ pendingSubmission: true, submissionModal: true, submissionError: true });
    }
    const inst = await res.json();
    this.setState({ pendingSubmission: false, submissionModal: true, submissionError: false });
  }


  public render = () => {

  const { isDesktop, isTablet, isMobile } = this.props.sizes;
  const { tos, pp } = this.state;

    //  CONSIDER:  Implement Router or React Navigation

    //  NOTE:  maxWidth of the content container an the paddings are taken from AirBnb.

    //  NOTE:  This will only work on web!
    (window as any).onscroll = () => {
      this.setState({ scrollPos: scrollY })
    }

    const screenHeight = Dimensions.get('window').height.valueOf();

    
      console.log("CHECK LINK");

      const ErrorPage = ({ text }: { text: string }) => {
        return (
          <View style={{ width: "100%", height: "100%", display: "flex", justifyContent: 'center', alignItems: 'center' }}>
            <View style={{ borderRadius: 20, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
              <Text style={{ fontFamily: "Poppins-SemiBold", color: '#444444', fontSize: 20 }}>Error</Text>
              <Text style={{ fontFamily: "Poppins-SemiBold", color: '#444444', fontSize: 15 }}>{ text }</Text>
            </View>
          </View>
        );
      }

      // checkLink();
    window.onfocus = () => this.forceUpdate();
    // window.onload = checkLink;

      // const checkLink = () => {
        if (window.location.href.includes("link")) {

          console.log("HAS LINK");
          // alert(window.history.length);
          // window.location.reload();
          // window.open("http://www.google.com");
          // return <Text>GOING</Text>;
          // // window.close();
    
          // return;
    
          
          //  Config
          const secDelay = 10;  //  Delay for 10 Seconds
    
          const params = new URLSearchParams(window.location.search);
    
          const timestamp = params.get("timestamp");
    
          if (timestamp == null) {
            const { origin, pathname } = window.location;
            // alert(JSON.stringify(window.location))
            let paramString = "?";
            params.forEach((value, key) => paramString += `${ key }=${ value }&`);
            paramString += `timestamp=${ (new Date()).getTime().toString() }`
            const newUrl = `${ origin }${ pathname }${ paramString }`;
            window.location.replace(newUrl);
            return;
          }
    
          const name = params.get("name");
          const letter = params.get("letter");
          const description = params.get("description");
          const backgroundColor = `${ params.get("background_color") || "f5b222" }`;
          const themeColor = `#${ params.get("theme_color") || "f5b222" }`;
          const link = params.get("link");
  
          if (!name) { return <ErrorPage text="No Name Provided" /> }
    
          //  REFERENCE (inspired by):  https://medium.com/@alshakero/how-to-setup-your-web-app-manifest-dynamically-using-javascript-f7fbee899a61
          const manifest = {
            "short_name": name,
            "name": name,
            "description": description,
            "start_url": "/link" + window.location.search,
            "background_color": backgroundColor,
            "theme_color": themeColor,
            "display": "standalone",
  
            //  TODO:  Get dynamic icons working, maybe from Base64, but perhaps also a remote URL.
  
            // "icons": [
            //   {
            //     "src": "icon/fox-icon.png",
            //     "sizes": "192x192",
            //     "type": "image/png"
            //   }
            // ],
          };
    
          const blob = new Blob([JSON.stringify(manifest)], { type: 'application/json' })
          const manifestURL = URL.createObjectURL(blob);
          document.body.style.backgroundColor = backgroundColor;
          document.getElementById("manifest-link")?.setAttribute("href", manifestURL);
    
          const msDelay = secDelay * 1000;
    
          const storedTimestamp = Number.parseInt(timestamp);
          const currentTimestamp = (new Date()).getTime();
    
          //  Determine if we're still in the window
          if (currentTimestamp - storedTimestamp <= msDelay) {
            return (
              <View style={{ height: '100%', width: '100%', backgroundColor: backgroundColor, alignItems: 'center', paddingTop: 85 }}>
                <Text style={{ color: 'white', fontSize: 200, fontWeight: "900" }}>{ letter }</Text>
                <View style={{ height: 70 }} />
                <View style={{ borderRadius: 20, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
                  <Text style={{ fontFamily: "Poppins-SemiBold", color: '#444444', fontSize: 15, padding: 15 }}>Tap the</Text>
                  <Image source={ require("../assets/share.png") } resizeMode="cover" style={{ width: 30, height: 30, borderRadius: 7 }} />
                  <Text style={{ fontFamily: "Poppins-SemiBold", color: '#444444', fontSize: 15, padding: 15 }}>button below to Save to Homescreen.</Text>
                </View>
              </View>
            );
          } else {
            if (link === null) {
              return <Text>{ name } + No Link was Provided</Text>
            } else {
              window.location.replace(link);
              return (
                <View style={{ height: '100%', width: '100%', backgroundColor: backgroundColor, alignItems: 'center', paddingTop: 80 }}>
                  <Text style={{ color: 'white', fontSize: 200, fontWeight: "900" }}>{ letter }</Text>
                  <View style={{ height: 70 }} />
                  <View style={{ borderRadius: 20, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
                    <Text style={{ fontFamily: "Poppins-SemiBold", color: '#444444', fontSize: 15, padding: 15 }}>Added to Homescreen</Text>
                  </View>
                </View>
              )
            }
          }
        }
      // }

    const Community = () => (
      <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#54d6c2', height: screenHeight }}>

          <View style={{ flex: 1, display: 'flex' }}>
            <View style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: 70 }}>
              <Text style={{ fontFamily: "Poppins-Bold", fontSize: 50, fontWeight: "900", color: 'white', letterSpacing: -.5, zIndex: 1 }}>Community</Text>
              <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 22, fontWeight: "900", color: 'white', letterSpacing: -.5, zIndex: 1, marginTop: -15, opacity: 0.85, marginLeft: 5 }}>Open Source Projects</Text>
              <View style={{ height: 70 }} />


              <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>

                <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

                  <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                    <NewImage tintColor="red" source={ require('../assets/Halia Logo.png') } style={{ width: 50, height: 50, marginTop: 5 }} resizeMode='contain' />
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 34, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Halia</Text>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 16, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12, marginLeft: 20 }}>Feature APIs</Text>
                    </View>
                  </View>

                  <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                    <NewImage tintColor="red" source={ require('../assets/Jector.png') } style={{ width: 50, height: 50, marginTop: 5 }} resizeMode='contain' />
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 34, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Jector</Text>
                      {/* NOTE:  This is like rule based CSS-like system? HM!  BUT I still want systems to MANAGE systems and their dependencies and stuff? hmm */}
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 16, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12, marginLeft: 20 }}>Component Injection</Text>
                    </View>
                  </View>

                  {/* TODO:  Make an open source tool to handle an action and spin off to the various systems!  MAYBE!  Hmmm...  AND maybe another non-JS, more system level thing to do so between externnal APIs!  MAEYB we can build a service for that?? HM!  Like a COORDINATOR? Hmmm... AND we want the ability to do NESTED. */}

                  <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                    <NewImage tintColor="red" source={ require('../assets/Almen.png') } style={{ width: 70, height: 70, marginTop: 5 }} resizeMode='contain' />
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      {/* Native Plugin name ideas:  Composition, Compi, Rover, Lomia, ahh, like the chinese nestig doll?? HM!  "Depot" for dependency management?  hmm MAYBE!  That's what I can call the mangaged service? HM!  Cross Section?  The idea is it's a PART of a cross-sectional "app" / system? HM!  Maybe like an "App Element???"  Alement?  Alment?  Almen! */}
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 34, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Almen</Text>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 16, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12, marginLeft: 20 }}>Native Plugins</Text>
                    </View>
                  </View>
                </View>

                <View style={{ width: 20 }} />

                <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

                  <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                    <NewImage tintColor="red" source={ require('../assets/Davel Logo.png') } style={{ width: 50, height: 50, marginTop: 5 }} resizeMode='contain' />
                    <View style={{ width: 25 }} />
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 32, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Davel</Text>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 14, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12 }}>Declarative Types</Text>
                    </View>
                  </View>

                    <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                      <NewImage tintColor="red" source={ require('../assets/Habor 2.png') } style={{ width: 50, height: 50, marginTop: 5 }} resizeMode='contain' />
                      <View style={{ width: 25 }} />
                      <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 32, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Habor</Text>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 14, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12 }}>Pluggable Backend</Text>
                      </View>
                    </View>

                    <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                      <NewImage tintColor="red" source={ require('../assets/Vise.png') } style={{ width: 50, height: 50, marginTop: 5 }} resizeMode='contain' />
                      <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {/* IDEAS:  Maybe call it "Bach?" MAYBE Vach? HM!  "Visual Composer? Hm!!", or "Vev", for visual develoopment?  Vode?  Visual Code.. I DON'T like the "Code" concept though, because EVERRYTHIGN is code to me!!!  Viselop.  Vilop... Visev.  Vise - Visual Developmet "" */}
                        <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 34, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Vise</Text>
                        <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 16, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12, marginLeft: 20 }}>Visual Developer</Text>
                      </View>
                    </View>
                </View>

                <View style={{ width: 70 }} />

                <View style={{ flex: 1.5, display: 'flex' }}>

                  <View style={{ backgroundColor: 'white', flex: 1, display: 'flex', overflow: 'hidden', borderRadius: 15, padding: 30 }}>
                    {/* <View style={{ flex: 1, backgroundColor: '#f5567c' }} /> */}
                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 30, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: '#aaaaaa' }}>Halia</Text>
                      <NewImage tintColor="red" source={ require('../assets/Halia Logo.png') } style={{ width: 80, height: 80 }} resizeMode='contain' />
                    </View>
                    
                  </View>
                </View>
              </View>

            </View>
          </View>
        </View>
    );


    const Projects = () => (
      <View style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#eeeeee', height: screenHeight }}>

        {/* NOTE:  The only reason I do this HERE with margin instead of above with padding is because it's  */}
        <View style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: 70 }}>


          <Text style={{ fontFamily: "Poppins-Bold", fontSize: 50, fontWeight: "900", color: '#666666', letterSpacing: -.5, zIndex: 1 }}>Products</Text>
          <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 22, fontWeight: "900", color: '#aaaaaa', letterSpacing: -.5, zIndex: 1, marginTop: -15, opacity: 0.85, marginLeft: 5 }}>For Sale Products</Text>
          <View style={{ height: 70 }} />

          <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>

            <View style={{ display: 'flex', flexDirection: 'column', flex: 1, borderRadius: 25, backgroundColor: 'white', overflow: 'hidden' }}>

              <View style={{ display: 'flex', flex: 2, alignItems: 'center', justifyContent: 'center' }}>
                <NewImage tintColor="red" source={ require('../assets/hessia_logo.png') } style={{ width: 200, height: 200, marginTop: 5 }} resizeMode='contain' />
              </View>
              

              <View style={{ backgroundColor: '#f74e8e', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontFamily: "Poppins-Bold", fontSize: 32, fontWeight: "900", letterSpacing: -1, zIndex: 1, color: 'white' }}>Hessia</Text>
                {/* Build Your Castle, Kingdom, Ocean, etc... Pluggable Lovable Systems */}
                <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 14, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Pluggable Systems</Text>
              </View>

            </View>

            <View style={{ width: 70 }} />


            <View style={{ display: 'flex', flexDirection: 'column', flex: 1, borderRadius: 25, backgroundColor: 'white', overflow: 'hidden' }}>

              <View style={{ display: 'flex', flex: 2, alignItems: 'center', justifyContent: 'center' }}>
                <NewImage tintColor="red" source={ require('../assets/MetaMesh Logo.png') } style={{ width: 75, height: 75, marginTop: 5 }} resizeMode='contain' />
              </View>
              

              <View style={{ backgroundColor: '#1ebef2', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontFamily: "Poppins-Bold", fontSize: 32, fontWeight: "900", letterSpacing: -1, zIndex: 1, color: 'white' }}>MetaMesh</Text>
                {/* Build Your Castle, Kingdom, Ocean, etc... Pluggable Lovable Systems */}
                <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 14, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Connected Systems</Text>
              </View>

            </View>

            <View style={{ width: 70 }} />

            
            <View style={{ display: 'flex', flexDirection: 'column', flex: 1, borderRadius: 25, backgroundColor: 'white', overflow: 'hidden' }}>

              <View style={{ display: 'flex', flex: 2, alignItems: 'center', justifyContent: 'center' }}>
                <NewImage tintColor="red" source={ require('../assets/Hoddle.png') } style={{ width: 150, height: 150, marginTop: 5 }} resizeMode='contain' />
              </View>
              

              <View style={{ backgroundColor: '#0aca9f', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ fontFamily: "Poppins-Bold", fontSize: 32, fontWeight: "900", letterSpacing: -1, zIndex: 1, color: 'white' }}>Hoddle</Text>
                {/* Build Your Castle, Kingdom, Ocean, etc... Pluggable Lovable Systems */}
                <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 14, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Pluggable Trading</Text>
              </View>

            </View>

          </View>

          </View>

        </View>
    );

    return (
      <View style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>

      
      <View style={{ width: '100%', display: 'flex', flexDirection: 'column', height: screenHeight, backgroundColor: 'white', overflow: 'hidden' }}>

        {/* Header */}
        {/* IDEA:  Could make the header show rounded corners on the bottom, increased width a bit, with a drop shadow. */}
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'absolute', top: 0 }}>
          

          {/* Header Left Side */}
          <View style={{ width: 70, height: 70, backgroundColor: '#aaaaaa', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderBottomRightRadius: 4 }}>
            <ImageBackground source={ require('../assets/logo.png') } style={{ width: 40, height: 40 }} resizeMode='contain' />
          </View>

          <View style={{ width: 15 }} />
          <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 22, fontWeight: "900", color: '#aaaaaa', letterSpacing: -1 }}>Oranda</Text>

        </View>

        {/* Hero Region */}
        <View style={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        

            

          {/* Hero Content */}
          <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

            {/* <ImageBackground source={ require('../assets/megapond.png') } style={{ width: '80%', height: '80%', position: 'absolute', opacity: 0.4, zIndex: 0 }} resizeMode='contain' /> */}

            {/* Center Title */}
            <Text style={{ fontFamily: "Poppins-Bold", fontSize: 88, fontWeight: "900", color: '#54d6c2', letterSpacing: -.5, marginTop: -35, zIndex: 1 }}>Oranda</Text>
            <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 28, fontWeight: "900", color: '#54d6c2', letterSpacing: -.5, marginTop: -35, zIndex: 1 }}>Tools to Help You Grow</Text>
          </View>



            {/* <View style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Image source={ require('../assets/megapond.png') } style={{ width: '80%', height: '80%' }} resizeMode='contain' />
            </View> */}

        </View>

        {/* Hero Footer */}
        <View style={{ position: 'absolute', top: screenHeight- 150, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingHorizontal: 84, height: 150 }}>
          
          <HoverableView style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 150, backgroundColor: 'white', paddingVertical: 10, paddingHorizontal: 30, borderRadius: 8 }} onHover={{ backgroundColor: '#f4f4f4' }}>
            <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 18, fontWeight: "900", color: '#aaaaaa', letterSpacing: -1 }}>Community</Text>
          </HoverableView>
          
          <View style={{ width: 25 }} />

          {/* <HoverableView style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 150, backgroundColor: 'white', paddingVertical: 10, paddingHorizontal: 30, borderRadius: 8 }} onHover={{ backgroundColor: '#f4f4f4' }}>
            <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 18, fontWeight: "900", color: '#aaaaaa', letterSpacing: -1 }}>Products</Text>
          </HoverableView> */}

          {/* <HoverableView style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 150, backgroundColor: 'white', paddingVertical: 10, paddingHorizontal: 30, borderRadius: 8 }} onHover={{ backgroundColor: '#f4f4f4' }}>
            <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 18, fontWeight: "900", color: '#aaaaaa', letterSpacing: -1 }}>Studio</Text>
          </HoverableView>

          <HoverableView style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 150, backgroundColor: 'white', paddingVertical: 10, paddingHorizontal: 30, borderRadius: 8 }} onHover={{ backgroundColor: '#f4f4f4' }}>
            <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 18, fontWeight: "900", color: '#aaaaaa', letterSpacing: -1 }}>Contact</Text>
          </HoverableView> */}

        </View>

        
        
        {/* Footer */}
        {/* <View style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', backgroundColor: '#444444', justifyContent: 'center', padding: 30 }}>
          <Text style={{ fontFamily: "soleil", fontSize: 14, fontWeight: "900", color: '#bfb3e4', letterSpacing: -.5 }}>PopSale</Text>
          <View style={{ height: 5 }} />
          <Text style={{ textAlign: 'center' ,fontFamily: "soleil", fontSize: 12, fontWeight: "600", color: 'white', letterSpacing: -.5 }}>Copyright 2020</Text>
        </View> */}

        </View>


        {/* <Community />

        <Projects /> */}


        {/* <View style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', height: screenHeight }}>

        <View style={{ flex: 1, display: 'flex', flexDirection: 'column', margin: 70 }}>


          <Text style={{ fontFamily: "Poppins-Bold", fontSize: 50, fontWeight: "900", color: '#777777', letterSpacing: -.5, zIndex: 1 }}>Elements</Text>
          <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 22, fontWeight: "900", color: '#aaaaaa', letterSpacing: -.5, zIndex: 1, marginTop: -15, opacity: 0.85, marginLeft: 5 }}>Tools to Help You Build</Text>
          <View style={{ height: 70 }} />

          <View style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>

                <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

                  <View style={{ backgroundColor: '#eeeeee', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                    <NewImage tintColor="red" source={ require('../assets/Halia Logo.png') } style={{ width: 50, height: 50, marginTop: 5 }} resizeMode='contain' />
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 34, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: '#777777' }}>Halia</Text>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 16, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12, marginLeft: 20 }}>Feature APIs</Text>
                    </View>
                  </View>

                  <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                    <NewImage tintColor="red" source={ require('../assets/Jector.png') } style={{ width: 50, height: 50, marginTop: 5 }} resizeMode='contain' />
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 34, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Jector</Text>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 16, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12, marginLeft: 20 }}>Component Injection</Text>
                    </View>
                  </View>


                  <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                    <NewImage tintColor="red" source={ require('../assets/Almen.png') } style={{ width: 70, height: 70, marginTop: 5 }} resizeMode='contain' />
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 34, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Almen</Text>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 16, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12, marginLeft: 20 }}>Native Plugins</Text>
                    </View>
                  </View>
                </View>

                <View style={{ width: 20 }} />

                <View style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>

                  <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                    <NewImage tintColor="red" source={ require('../assets/Davel Logo.png') } style={{ width: 50, height: 50, marginTop: 5 }} resizeMode='contain' />
                    <View style={{ width: 25 }} />
                    <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 32, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Davel</Text>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 14, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12 }}>Declarative Types</Text>
                    </View>
                  </View>

                    <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                      <NewImage tintColor="red" source={ require('../assets/Habor 2.png') } style={{ width: 50, height: 50, marginTop: 5 }} resizeMode='contain' />
                      <View style={{ width: 25 }} />
                      <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 32, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Habor</Text>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 14, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12 }}>Pluggable Backend</Text>
                      </View>
                    </View>

                    <View style={{ backgroundColor: '#7ae4d4', flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden', borderRadius: 15, opacity: 1, marginBottom: 20, justifyContent: 'center', alignItems: 'center' }}>
                      <NewImage tintColor="red" source={ require('../assets/Vise.png') } style={{ width: 50, height: 50, marginTop: 5 }} resizeMode='contain' />
                      <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 34, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white' }}>Vise</Text>
                        <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 16, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: 'white', marginTop: -12, marginLeft: 20 }}>Visual Developer</Text>
                      </View>
                    </View>
                </View>

                <View style={{ width: 70 }} />

                <View style={{ flex: 1.5, display: 'flex' }}>

                  <View style={{ backgroundColor: 'white', flex: 1, display: 'flex', overflow: 'hidden', borderRadius: 15, padding: 30 }}>
                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 30, fontWeight: "900", letterSpacing: -.5, zIndex: 1, color: '#aaaaaa' }}>Halia</Text>
                      <NewImage tintColor="red" source={ require('../assets/Halia Logo.png') } style={{ width: 80, height: 80 }} resizeMode='contain' />
                    </View>
                    
                  </View>
                </View>
              </View>

          </View>

        </View> */}


{/* 
        <View style={{ display: 'flex', flexDirection: 'row' }}>

          <View style={{ flexBasis: 0, flexGrow: 1, flexShrink: 0, display: 'flex', flexDirection: 'column', backgroundColor: '#54d6c2', padding: 70, paddingTop: 140, height: 700, borderRadius: 25 }}>
            <Text style={{ fontFamily: "Poppins-SemiBold", fontSize: 50, fontWeight: "900", color: 'white', letterSpacing: -.5, marginTop: -35, zIndex: 1 }}>Community</Text>
          </View>

          <View style={{ flexBasis: 0, flexGrow: 1, flexShrink: 0, display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 70, paddingTop: 140, height: 700, alignItems: 'center', justifyContent: 'center' }}>
            <Image source={ require('../assets/megapond.png') } style={{ width: '100%', height: '100%' }} resizeMode='contain' />
          </View>
        </View> */}

      </View>
      );
  }
}

export const withSizes = (Component: any) => {
  return () => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isTablet = useMediaQuery({ minWidth: 500, maxWidth: 767 });
    const isMobile = useMediaQuery({ maxWidth: 499 });
    const sizes = {
      isDesktop,
      isTablet,
      isMobile
    }
    return <Component sizes={ sizes } />;
  }
}

export const AppComponent = withSizes(AppComponentBase);
